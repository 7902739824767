/* eslint-disable consistent-return */
import auth0 from "auth0-js";

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
      dbConnectionName: "Username-Password-Authentication",
      responseType: "token id_token",
      scope: "openid profile email user_metadata app_metadata",
      jwksURI: null,
      popupOrigin: null
    });
    this.authFlag = "isLoggedIn";
    this.idToken = "";
    this.accessToken = "";
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.silentAuth = this.silentAuth.bind(this);
  }

  login() {
    this.auth0.authorize();
  }

  getIdToken = () => this.idToken;

  getAccessToken = () => {
    if (!this.accessToken) {
      // try local storage
      const token = localStorage.getItem("access_token");
      if (token) {
        return token;
      }
      return null;
    }
    return this.accessToken;
  };

  handleAuthentication = () =>
    new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (!authResult) {
          reject(err);
        }
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        resolve(authResult);
      });
    });

  setSession(authResult) {
    this.idToken = authResult.idToken;
    this.accessToken = authResult.accessToken;
    // set the time that the id token will expire at
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem(this.authFlag, JSON.stringify(true));
  }

  logout() {
    // Clear access token and ID token from local storage

    localStorage.setItem(this.authFlag, JSON.stringify(false));
    localStorage.setItem("access_token", "");
    localStorage.removeItem("index");

    this.auth0.logout({
      returnTo: process.env.REACT_APP_CLIENT_URL,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID
    });
  }

  silentAuth() {
    if (this.isAuthenticated()) {
      return new Promise((resolve, reject) => {
        this.auth0.checkSession({}, (err, authResult) => {
          if (err) {
            return reject(err);
          }
          this.setSession(authResult);
          resolve(authResult);
        });
      });
    }
  }

  isAuthenticated() {
    return JSON.parse(localStorage.getItem(this.authFlag));
  }
}

const auth = new Auth();

export default auth;
