const reports = {
  name: "Reports",
  url: "/reports",
  icon: "fa fa-chart-line",
  children: [
    {
      name: "History Report",
      url: "/reports/history",
      icon: "fa fa-history"
    },
    {
      name: "Member Load",
      url: "/reports/memberLoad",
      icon: "fa fa-people-carry"
    },
    {
      name: "Burndown Chart",
      url: "/reports/sprintBurndown",
      icon: "fa fa-chart-line"
    },
    {
      name: "Velocity Trend",
      url: "/reports/velocityTrend",
      icon: "fa fa-chart-bar"
    },
    {
      name: "Cumulative Flow",
      url: "/reports/cumulativeFlow",
      icon: "fa fa-chart-area"
    },
    {
      name: "End of Run",
      url: "/reports/endOfRun",
      icon: "fa fa-flag-checkered"
    }
  ]
};

export default {
  studentNew: [
    {
      name: "Welcome",
      url: "/welcome",
      class: "mt-auto",
      icon: "fa fa-star"
    },
    {
      name: "Settings",
      url: "/settings",
      icon: "fa fa-cog"
    }
  ],
  studentStarted: [
    {
      name: "Runs",
      url: "/teams",
      class: "mt-auto",
      icon: "fa fa-tasks"
    },
    {
      name: "Settings",
      url: "/settings",
      icon: "fa fa-cog"
    }
  ],
  studentHasActiveTeamItems: [
    {
      name: "Product Backlog",
      url: "/backlog",
      icon: "fa fa-table"
    },
    {
      name: "Scrum Board",
      url: "/scrumboard",
      icon: "fa fa-boxes"
    },
    {
      name: "Calendar",
      url: "/calendar",
      icon: "fa fa-calendar"
    },
    {
      name: "Run Sprint",
      url: "/run-sprint",
      icon: "fa fa-redo"
    },

    reports,

    {
      name: "Runs",
      url: "/teams",
      class: "mt-auto",
      icon: "fa fa-tasks"
    },
    {
      name: "Settings",
      url: "/settings",
      icon: "fa fa-cog"
    }
  ],
  studentHasCompletedTeam: [
    reports,

    {
      name: "Runs",
      url: "/teams",
      class: "mt-auto",
      icon: "fa fa-tasks"
    },
    {
      name: "Settings",
      url: "/settings",
      icon: "fa fa-cog"
    }
  ],
  studentHasWaitingTeams: [
    reports,
    {
      name: "Runs",
      url: "/teams",
      class: "mt-auto",
      icon: "fa fa-tasks"
    },
    {
      name: "Settings",
      url: "/settings",
      icon: "fa fa-cog"
    }
  ],
  studentHasNoCurrentTeam: [
    {
      name: "Runs",
      url: "/teams",
      class: "mt-auto",
      icon: "fa fa-tasks"
    },
    {
      name: "Settings",
      url: "/settings",
      icon: "fa fa-cog"
    }
  ],
  unknownState: [
    {
      name: "Settings",
      url: "/settings",
      icon: "fa fa-cog"
    }
  ],
  instructorStarted: [
    {
      name: "Runs",
      url: "/teams",
      class: "mt-auto",
      icon: "fa fa-tasks"
    },
    {
      icon: "fa fa-user-graduate",
      url: "/management",
      name: "Instructor Tools",
      class: "mt-auto",
      children: [
        {
          name: "Students",
          url: "/management/students",
          icon: "fa fa-user-graduate"
        },
        {
          name: "Classrooms",
          url: "/management/classrooms",
          icon: "fa fa-chalkboard-teacher"
        }
      ]
    }
  ],
  instructorItems: [
    {
      icon: "fa fa-book",
      name: "SimAgile",
      children: [
        {
          name: "Product Backlog",
          url: "/backlog",
          icon: "fa fa-table"
        },
        {
          name: "Scrum Board",
          url: "/scrumboard",
          icon: "fa fa-boxes"
        },
        {
          name: "Calendar",
          url: "/calendar",
          icon: "fa fa-calendar"
        },
        {
          name: "Run Sprint",
          url: "/run-sprint",
          icon: "fa fa-redo"
        },

        reports
      ]
    },
    {
      name: "Runs",
      url: "/teams",
      class: "mt-auto",
      icon: "fa fa-tasks"
    },
    {
      icon: "fa fa-user-tie",
      url: "/management/students",
      class: "mt-auto",
      name: "Instructor Tools",
      children: [
        {
          name: "Students",
          url: "/management/students",
          icon: "fa fa-user-graduate"
        },
        {
          name: "Classrooms",
          url: "/management/classrooms",
          icon: "fa fa-chalkboard-teacher"
        }
      ]
    },

    {
      name: "Settings",
      url: "/settings",
      icon: "fa fa-cog"
    }
  ]
};
