import React from "react";
import styled from "styled-components";
import people from "../../static/images/people";

const InteractionSpeakerReport = ({ workerData }) => {
  const { name: workerName } = workerData || {};
  const workerFileName = workerName.replace(/\s/g, "").replace(/\./g, "");

  return (
    <>
      <SpeakerContainer>
        <img
          src={people[workerFileName]}
          alt={workerName}
          id={workerFileName}
        />
        <div className="workerName">{workerName}</div>
      </SpeakerContainer>
    </>
  );
};

export default InteractionSpeakerReport;

const SpeakerContainer = styled.div`
  img {
    width: 45px;
    height: auto;
    border-radius: 50%;
  }

  div.workerName {
    margin: 0px 0 15px 0;
    font-size: 14px;
    text-align: left;
  }
`;
