import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import Select from "react-select";
import { Col, Row, Button, Card, CardHeader, CardBody } from "reactstrap";
import ReactToPrint from "react-to-print";
import moment from "moment";
import Loading, { Error } from "../../components/Loading";
import { HistoryWeek } from "./components/HistoryItems/HistoryWeek";
import { HISTORY_QUERY } from "../../graphql/queries";
import { useAsyncState } from "../../utilities/utils";
import { ProjectContext } from "../../GlobalState";
import UserHelper from "../../UserHelper";

const PrintDiv = styled.div`
  @media print {
    @page {
      size: landscape;
    }
    padding: 50px;
  }
`;

const HistoryReport = ({ currentUser }) => {
  const [state] = useContext(ProjectContext);
  const [weekNumber, setWeekNumber] = useAsyncState(() => {
    let lastHistoryWeek = state.currentWeekNumber - 1;
    if (state.currentTeamStatus === "COMPLETED") {
      lastHistoryWeek = state.currentWeekNumber;
    }
    return lastHistoryWeek;
  });
  const [historyRef, setHistoryRef] = useAsyncState(null);
  const [weeks] = useAsyncState(() => {
    const wks = [];
    // if the project is still running, then the last history week is the last week run.

    for (let i = weekNumber; i > 0; i -= 1) {
      wks.push({
        label: `Week ${i}`,
        value: i.toString()
      });
    }
    return [...wks];
  });
  const { loading, error, data } = useQuery(HISTORY_QUERY, {
    variables: { teamId: state.currentTeamId },
    skip: !state.currentTeamId
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  // eslint-disable-next-line no-unused-vars
  let documentTitle = null;
  const afterPrint = () => {
    documentTitle = document.title;
  };

  const beforePrint = newTitle => {
    documentTitle = newTitle;
  };

  const onChangeWeekNumber = value => {
    setWeekNumber(parseInt(value.value, 10));
  };

  let date;
  if (data && data.historyItems) {
    data.historyItems.forEach(comp => {
      if (
        comp.itemComponent === "REPORTHDR" &&
        comp.weekNumber === weekNumber
      ) {
        date = comp.itemProperties[2].itemValue;
      }
    });
  }

  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col md="3">
                  <Select
                    name="weekNumber"
                    aria-labelledby="weekNumberLabel"
                    value={
                      weeks &&
                      weeks.length > 0 &&
                      weeks.find(w => parseInt(w.value, 10) === weekNumber)
                    }
                    options={weeks}
                    onChange={onChangeWeekNumber}
                  ></Select>
                </Col>
                <Col>
                  <span className="float-sm-right">
                    {data && data.historyItems && data.historyItems.length > 0 ? (
                      <ReactToPrint
                        trigger={() => (
                          <Button color="secondary">
                            <i className="far fa-file-pdf" /> Print
                          </Button>
                        )}
                        content={() => historyRef}
                        onBeforePrint={() =>
                          beforePrint(`History ReportWeek ${weekNumber}`)
                        }
                        onAfterPrint={() => afterPrint()}
                        documentTitle={`${"History Report" +
                          "_" +
                          "Week" +
                          `${weekNumber.toString()}` +
                          "_"}${UserHelper.firstName(
                          currentUser,
                          state
                        )}${UserHelper.lastName(currentUser, state)}_${moment(
                          date
                        ).format("YYYY-MM-DD HH:mm:ss")}_${UserHelper.teamId(
                          currentUser,
                          state
                        )}`}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              <PrintDiv
                id={`h${weekNumber.toString()}`}
                ref={el => {
                  setHistoryRef(el);
                }}
              >
                {!data || !data.historyItems || data.historyItems.length === 0 ? (
                  <p>No History available yet</p>
                ) : (
                  <HistoryWeek
                    key={weekNumber}
                    weekNumber={weekNumber}
                    componentList={data.historyItems
                      .filter(h => h.weekNumber === weekNumber)
                      .sort((a, b) => a.id - b.id)}
                  />
                )}
              </PrintDiv>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HistoryReport;
