/* eslint-disable import/prefer-default-export */
import React from "react";
import { Card, CardBody } from "reactstrap";
import InteractionSpeakerReport from "../../../../components/Worker/InteractionSpeakerReport";

export const PlayScript = props => {
  const tempWorkerData = {
    name: "Agile Coach"
  };

  return (
    <Card>
      <CardBody>
        {" "}
        <div className="resource-Profile">
          <InteractionSpeakerReport workerData={tempWorkerData} />
        </div>{" "}
        <div className="report-Item_Content">
          <p>{props.properties[0].itemValue}</p>
        </div>
      </CardBody>
    </Card>
  );
};
