/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
/* TODO - get rid of the need for these two eslint statements - this code needs a rewrite */

import React, { useContext } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Badge, Nav, NavItem } from "reactstrap";
import classNames from "classnames";
import { isNewUser } from "utilities/utils";
import nav from "./components/nav";
import SidebarMinimizer from "./components/SidebarMinimizer";
import { ProjectContext } from "../../GlobalState";

const Sidebar = props => {
  const [state] = useContext(ProjectContext);

  const handleClick = e => {
    e.preventDefault();
    e.target.parentElement.classList.toggle("open");
  };

  const activeRoute = routeName => {
    if (routeName === "Reports" || routeName === "Classroom Management") {
      return "nav-item nav-dropdown";
    }

    const parentSlug = `/${routeName.toLowerCase()}`;

    if (props.location && props.location.pathname) {
      return props.location.pathname.indexOf(parentSlug) > -1
        ? "nav-item nav-dropdown open"
        : "nav-item nav-dropdown open";
    }
    return "nav-item nav-dropdown open";
  };

  // todo Sidebar nav secondLevel
  // secondLevelActive(routeName) {
  //   return props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
  // }

  // this is so not exact - the menu should change depending on the status of team
  const navSelector = () => {
    if (props.currentUser.role === "STUDENT" && isNewUser(props.currentUser)) {
      return nav.studentNew;
    }
    if (props.currentUser.role === "STUDENT") {
      if (state.currentTeamStatus === "COMPLETED") {
        return nav.studentHasWaitingTeams;
      }
      if (state.hasActiveTeam) {
        // need to know if student has active project or not.
        return nav.studentHasActiveTeamItems;
      }
      if (!state.currentTeamStatus) {
        return nav.studentHasNoCurrentTeam;
      }
      return nav.studentHasWaitingTeams;
    }

    if (props.currentUser.role === "INSTRUCTOR") {
      if (state.hasActiveTeam) {
        // need to know if student has active project or not.
        return nav.instructorItems;
      }
      return nav.instructorStarted;
    }
    return nav.unknownState;
  };

  // badge addon to NavItem
  const thisBadge = badge => {
    if (badge) {
      const classes = classNames(badge.class);
      return (
        <Badge className={classes} color={badge.variant}>
          {badge.text}
        </Badge>
      );
    }
    return <span />;
  };

  // simple wrapper for nav-title item
  const wrapper = item =>
    item.wrapper && item.wrapper.element
      ? React.createElement(
          item.wrapper.element,
          item.wrapper.attributes,
          item.name
        )
      : item.name;

  // nav list section title
  const thisTitle = (title, key) => {
    const classes = classNames("nav-title", title.class);
    return (
      <li key={key} className={classes}>
        {wrapper(title)}{" "}
      </li>
    );
  };

  // nav list divider
  const thisDivider = (divider, key) => <li key={key} className="divider" />;

  // nav item with nav link
  const navItem = (item, key) => {
    const classes = classNames(item.class);
    const variant = classNames(
      "nav-link",
      item.variant ? `nav-link-${item.variant}` : ""
    );
    if (
      item.name === "Runs" ||
      props.currentUser.role === "INSTRUCTOR" ||
      props.startedProject
    ) {
      return (
        <NavItem key={key} className={classes}>
          <NavLink
            disabled={item.name !== "Runs" && !props.startedProject}
            to={item.url}
            className={variant}
            activeClassName="active"
          >
            <i className={item.icon} />
            <p>{item.name}</p>
            {thisBadge(item.badge)}
          </NavLink>
        </NavItem>
      );
    }
    return null;
  };

  // nav dropdown
  const navDropdown = (item, key) => {
    return (
      <li key={key} className={activeRoute(item.name)}>
        <button
          type="button"
          className="nav-link nav-dropdown-toggle"
          onClick={event => handleClick(event)}
        >
          <i className={item.icon} />
          {item.name} +
        </button>
        <ul className="nav-dropdown-items">{navList(item.children)}</ul>
      </li>
    );
  };

  // nav link
  const navLink = (item, idx) =>
    item.title
      ? thisTitle(item, idx)
      : item.divider
      ? thisDivider(item, idx)
      : item.children
      ? navDropdown(item, idx)
      : navItem(item, idx);

  // nav list
  const navList = items => items.map((item, index) => navLink(item, index));

  // const userNavItems =
  //   props.currentUser.role === "INSTRUCTOR"
  //     ? nav.instructorItems
  //     : nav.studentItems;

  // sidebar-nav root
  return (
    <div className="sidebar">
      <nav className="sidebar-nav">
        <Nav>{navList(navSelector())}</Nav>
      </nav>
      <SidebarMinimizer />
    </div>
  );
};

export default withRouter(Sidebar);
