import React from "react";
import styled from "styled-components";
import people from "../../static/images/people";
import { roles } from "../../static/images/people";

const InteractionSpeaker = ({ workerData }) => {
  const { name: workerName } = workerData || {};
  const workerFileName = workerName.replace(/\s/g, "").replace(/\./g, "");

  return (
    <>
      <SpeakerContainer>
        <img
          src={people[workerFileName]}
          alt={workerName}
          id={workerFileName}
        />
        <div className="workerName">{workerName}</div>
        <div className="roleName">{roles[workerFileName]}</div>
      </SpeakerContainer>
    </>
  );
};

export default InteractionSpeaker;

const SpeakerContainer = styled.div`
  img {
    width: 45px;
    height: auto;
    border-radius: 50%;
  }

  div.workerName {
    margin-top: 7px;
    font-size: 14px;
    text-align: center;
  }

  div.roleName {
    margin-top: 0;
    font-size: 13px;
    text-align: center;
    font-style: italic;
  }
`;
