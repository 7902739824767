import React from "react";
import InteractionSpeaker from "../../../components/Worker/InteractionSpeaker";
import "../RunWeek.css";

const AssistantPanel = props => {
  const tempWorkerData = {
    name: "Agile Coach"
  };

  return (
    <div className="component-Alert">
      <div className="alert-Resource">
        <InteractionSpeaker workerData={tempWorkerData} />
      </div>{" "}
      <div className="alert-Body">
        {props &&
          props.playScriptList &&
          props.playScriptList.map(s => (
            <div key={99} id="message" className="assistance-message">
              {s}
            </div>
          ))}
      </div>
      <br />
      <br />
    </div>
  );
};
export default AssistantPanel;
