/* eslint-disable no-unreachable */
/* eslint-disable no-shadow */
import React from "react";
import styled from "styled-components";
import { Alert } from "reactstrap";

const LoadingLayout = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  > span {
    margin-right: 0.5rem;
  }
`;

const Loading = ({ text }) => (
  <LoadingLayout>
    <span
      className="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    />{" "}
    {text}
  </LoadingLayout>
);

const Error = ({ error }) => {
  throw error;
  if (!error || !error.message) return null;
  return (
    <div className="float-left">
      <Alert width="50%" style={{ textAlign: "left" }} color="danger">
        <p>
          <strong>An error has occurred</strong>
        </p>
        <p>
          <strong>Step 1: </strong>Please reload the page and try your operation
          again.
        </p>
        <p>If that doesn&rsquo;t work:</p>
        <p>
          <strong>Step 2:</strong>Send an email with your username and a
          screenshot of this screen to support@simulationpl.com.
        </p>
        <p>
          <strong>Error message: </strong>
          {error.message}
        </p>
      </Alert>
    </div>
  );
};

const Info = ({ info }) => {
  if (info) return <Alert color="info">{info}</Alert>;
  return <span />;
};

Error.defaultProps = {
  error: {}
};

export default Loading;
export { Error, Info };
