import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";

const LinkContainer = styled.div`
  text-decoration: underline;
  font-weight: bold;
`;

const ConditionMessage = ({ htmlContent, showLinks = true }) => {
  const [parsedHtml, setParsedHtml] = useState(null);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    if (htmlContent) {
      const linksArray = [];

      // Parse HTML and extract links
      const parsed = ReactHtmlParser(htmlContent, {
        transform: (node, index) => {
          if (node.type === "tag" && node.name === "a") {
            linksArray.push({
              href: node.attribs.href,
              text: node.children[0].data
            });
            return null; // Remove the <a> element from the rendered content
          }
        }
      });

      setParsedHtml(parsed);
      setLinks(linksArray);
    }
  }, [htmlContent]);

  return (
    <div>
      <div>{parsedHtml}</div>
      {showLinks &&
        links.map((link, index) => (
          <>
            <br></br>
            <LinkContainer>
              <Link to={link.href}>{link.text}</Link>
            </LinkContainer>
          </>
        ))}
    </div>
  );
};

export default ConditionMessage;
