/* eslint-disable no-nested-ternary */
import React from "react";
import { Card, CardBody } from "reactstrap";
import Worker from "../../../../components/Worker/Worker";
import SailBoat from "../../../../static/images/people/SailBoat.png";
import ConditionMessage from "../../../RunWeek/components/ConditionMessage";

/*
0 = worker
1 = message
2 = reponse
3 = condition id
*/

// eslint-disable-next-line import/prefer-default-export
export const Condition = props => {
  const tempWorkerData = {
    name:
      props.properties[0].itemValue === "Sailboat"
        ? "Farley Gnu"
        : props.properties[0].itemValue
  };
  return (
    <Card>
      <CardBody>
        {" "}
        <div className="resource-Profile">
          <Worker imageOnly workerData={tempWorkerData} />
        </div>{" "}
        <div className="resource-Body">
          <p>
            {props.properties[0].itemValue === "Buki"
              ? "Agile Coach"
              : props.properties[0].itemValue === "Sailboat"
              ? "Farley Gnu"
              : props.properties[0].itemValue}
          </p>
        </div>
        <div className="report-Item_Content">
          {props.properties[0].itemValue === "Sailboat" ? (
            <img alt="Sailboat" height="30%" width="80%" src={SailBoat} />
          ) : (
            <span />
          )}

          <ConditionMessage
            htmlContent={props.properties[1].itemValue}
            showLinks={false}
          />

          <br />
          <p>
            <strong>
              <em>Your response:</em>
            </strong>
            {"     "}
            {props.properties[2].itemValue}
          </p>
        </div>
        {props.properties[3] && <small>{props.properties[3].itemValue}</small>}
      </CardBody>
    </Card>
  );
};
