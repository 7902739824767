import React, { useContext, useEffect } from "react";

import { Col, Row, Card, CardHeader, CardBody } from "reactstrap";
import SprintSelector from "../../components/SprintSelector";
import MemberLoadTable from "./components/MemberLoadTable";
import { ProjectContext } from "../../GlobalState";

const MemberLoad = () => {
  const [state, dispatch] = useContext(ProjectContext);

  useEffect(() => {
    // Anything in here is fired on component mount.
    return () => {
      // Anything in here is fired on component unmount.
      const payload = {
        option: {}
      };
      dispatch({
        type: "UPDATE_SELECTED_SPRINT_IN_DROPDOWN",
        payload
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state.currentTeamId) {
    return <div>No run available</div>;
  }

  return (
    <div>
      <Card>
        <CardHeader>
          <Row>
            <Col md="3">
              <SprintSelector
                defaultValue={
                  state &&
                    state.selectedSprintOption &&
                    state.selectedSprintOption.value
                    ? state.selectedSprintOption.value.toString()
                    : "1"
                }
                teamId={state.currentTeamId}
                controlName="sprintNumber"
              />
            </Col>
            <Col></Col>
          </Row>
        </CardHeader>
        <CardBody>
          <MemberLoadTable
            teamId={state.currentTeamId}
            sprintId={parseInt(state.selectedSprintOption.value, 10)}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default MemberLoad;
