import React from "react";
import styled from "styled-components";
import { Badge } from "reactstrap";
import { UncontrolledTooltip } from "../styled/UncontrolledTooltip";
import people from "../../static/images/people";

const Worker = ({ workerData, imageOnly, skills }) => {
  const { name, availability = 1, storyAssignments } = workerData || {};

  let badgeColor;
  switch (availability) {
    case 1:
      badgeColor = "success";
      break;
    case 0:
      badgeColor = "secondary";
      break;
    default:
      badgeColor = "primary";
  }
  const skill = "Team Member";
  /*
prev code, kept for posterity.  this skill name gets overridden in display
{skills && 
  skills.find(s => s.skillNumber === workerData.skillNumber)
  .skillName} */
  if (imageOnly && name) {
    return (
      <ImageOnlyWorker>
        <img
          src={people[`${name.replace(/\s/g, "").replace(/\./g, "")}`]}
          alt={name}
          id={`${name.replace(/\s/g, "").replace(/\./g, "")}-story-profile`}
        />
        <UncontrolledTooltip
          placement="top"
          target={`${name.replace(/\s/g, "").replace(/\./g, "")}-story-profile`}
        >
          {name}
        </UncontrolledTooltip>
      </ImageOnlyWorker>
    );
  }
  if (name === undefined) {
    return (
      <StyledWorker>
        <img src="http://placekitten.com/45/45" alt="no idea" />
        <div>
          <Badge color="danger">name missing</Badge>
          <small>{skill}</small>
          {availability === 0 ? <Badge color={badgeColor}>on story</Badge> : ""}
        </div>
      </StyledWorker>
    );
  }
  return (
    <StyledWorker className={availability === 1 ? "available" : "unavailable"}>
      {storyAssignments &&
      storyAssignments.length > 0 &&
      storyAssignments[0].story ? (
        <Badge
          className="story-badge"
          color="info"
          id={`${name.replace(/\s/g, "").replace(/\./g, "")}-story-badge`}
        >
          {storyAssignments[0].id}
        </Badge>
      ) : (
        ""
      )}
      <img
        src={people[`${name.replace(/\s/g, "").replace(/\./g, "")}`]}
        alt={name}
      />
      <div>
        {name}
        <small>{skill}</small>
      </div>
      {storyAssignments &&
      storyAssignments.length > 0 &&
      storyAssignments[0].story ? (
        <UncontrolledTooltip
          placement="left"
          target={`${name.replace(/\s/g, "").replace(/\./g, "")}-story-badge`}
        >
          Story number
        </UncontrolledTooltip>
      ) : (
        ""
      )}
    </StyledWorker>
  );
};

export default Worker;

const ImageOnlyWorker = styled.div`
  img {
    width: 45px;
    height: auto;
    border-radius: 50%;
  }
`;

const StyledWorker = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background-color: white;
  padding: 10px;
  position: relative;

  .story-badge {
    position: absolute;
    top: 0;
    left: 0;
  }

  img {
    width: 45px;
    height: auto;
    border-radius: 50%;
  }

  > div {
    margin-left: 10px;
    line-height: 1.3;
  }

  &.unavailability {
    cursor: not-allowed;
    img,
    > div {
      opacity: 0.4;
    }
  }

  small {
    display: block;
    font-size: 0.75em;
    color: #818181;
  }

  .badge {
    font-size: 0.7em;
    border-radius: 1px;
  }
`;
