/* eslint-disable prefer-destructuring */
import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Col, Row, Button, Card, CardHeader, CardBody } from "reactstrap";
import ReactToPrint from "react-to-print";
import { ProjectContext } from "GlobalState";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import CumulativeFlowDiagram from "./components/CumulativeFlowDiagram";
import Loading, { Error } from "../../components/Loading";
import UserHelper from "../../UserHelper";

const GET_CUMULATIVE_FLOW = gql`
  query GET_CUMULATIVE_FLOW($teamId: Int!) {
    cumulativeFlow(teamId: $teamId) {
      entries {
        runDay
        storiesInBacklog
        storiesInProgress
        completedStories
      }
    }
  }
`;

const PrintDiv = styled.div`
  @media print {
    @page {
      size: landscape;
    }
    padding: 50px;
  }
`;

const CumulativeFlow = ({ currentUser }) => {
  const [elementRef, setElementRef] = useState(null);
  const [state] = useContext(ProjectContext);
  const teamId = state.currentTeamId;
  const { currentRunDay } = state;
  const today = new Date();
  const date = `${today.getFullYear()}-${today.getMonth() +
    1}-${today.getDate()}`;
  const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;

  const { loading, error, data } = useQuery(GET_CUMULATIVE_FLOW, {
    variables: { teamId },
    fetchPolicy: "no-cache"
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const chartData = [
    [
      "days",
      "Done",
      { role: "tooltip", type: "string", p: { html: true } },
      "In Progress",
      { role: "tooltip", type: "string", p: { html: true } },
      "To do",
      { role: "tooltip", type: "string", p: { html: true } }
    ]
  ];

  let completedStories = 0;
  let storiesInProgress = 0;
  let storiesInBacklog = 0;

  const allEntriesEmpty = data.cumulativeFlow.entries.every(
    entryRecord =>
      entryRecord.storiesInBacklog === 0 &&
      entryRecord.storiesInProgress === 0 &&
      entryRecord.completedStories === 0
  );

  if (allEntriesEmpty) {
    return <div>No data available</div>;
  }

  for (let i = 0; i < currentRunDay - 1; i++) {
    const entry = data.cumulativeFlow.entries[i];
    completedStories = entry.completedStories;
    storiesInProgress = completedStories + entry.storiesInProgress;
    storiesInBacklog = entry.storiesInBacklog;   

    chartData.push([
      Number(entry.runDay),
      completedStories,
      `<div class="graph-margin">Day : ${entry.runDay}</div> <div class="graph-margin">Done : ${completedStories} Points</div>`,
      storiesInProgress,
      `<div class="graph-margin">Day : ${entry.runDay}</div> <div class="graph-margin">In Progress : ${storiesInProgress} Points</div>`,
      storiesInBacklog,
      `<div class="graph-margin">Day : ${entry.runDay}</div> <div class="graph-margin">To do : ${storiesInBacklog} Points</div>`
    ]);
  }

  // eslint-disable-next-line no-unused-vars
  let documentTitle = null;

  const afterPrint = () => {
    documentTitle = document.title;
  };

  const beforePrint = newTitle => {
    documentTitle = newTitle;
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col md="3"></Col>
          <Col>
            <span className="float-sm-right">
              <ReactToPrint
                trigger={() => (
                  <Button color="secondary">
                    <i className="far fa-file-pdf" /> Print
                  </Button>
                )}
                content={() => elementRef}
                onBeforePrint={() =>
                  beforePrint(
                    `Burndown Chart Sprint ${state.currentSprintNumber}`
                  )
                }
                onAfterPrint={() => afterPrint()}
                documentTitle={`${"Cumulative Flow Report " +
                  "_"}${UserHelper.firstName(
                  currentUser,
                  state
                )}${UserHelper.lastName(
                  currentUser,
                  state
                )}_${date}_${time}_${UserHelper.teamId(currentUser, state)}`}
              />
            </span>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <PrintDiv
          id={`h${state.currentSprintNumber.toString()}`}
          ref={el => {
            setElementRef(el);
          }}
        >
          <CumulativeFlowDiagram
            teamId={UserHelper.teamId(currentUser, state)}
            user={`${UserHelper.firstName(
              currentUser,
              state
            )} ${UserHelper.lastName(currentUser, state)}`}
            runNumber={state.runSequenceNumber}
            chartData={chartData}
          />
        </PrintDiv>
      </CardBody>
    </Card>
  );
};

export default CumulativeFlow;
