import React, { Fragment, Component } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import CustomProperties from "react-custom-properties";
import { reactPlugin, appInsights } from "appInsights";
import {
  AppInsightsErrorBoundary,
  AppInsightsContext
} from "@microsoft/applicationinsights-react-js";
import auth from "./Auth";
import App from "./App";
import Callback from "./components/Callback";
import GuardedRoute from "./components/GuardedRoute";
import Login from "./routes/Login/Login";
import Page404 from "./routes/Page404/Page404";
import "./static/styles/sp-starter-styles.css";

const customVariables = {
  "--primary-color": "#5378ad",
  "--primary-color-dark": "#42608a",
  "--secondary-color": "#678798",
  "--secondary-color-dark": "#536c79",
  "--tertiary-color": "#63c2de",
  "--gray-color": "#e4e5e6",
  "--black-color": "#29363d",
  "--red-color": "#E66A6A"
};

const GlobalStyle = createGlobalStyle`
  @media (min-width: 576px) {
    body .modal-dialog:not(.modal-lg) {
      max-width: 550px;
    }
  }

  body h5 {
    font-size: 1rem;
  }

  .modal-open .modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .modal .modal-header {
    align-items: flex-start;
  }

  body .popover.bs-popover-bottom .arrow,
  body .popover.bs-popover-auto[x-placement^="bottom"] .arrow {
    left: 35% !important;
  }

  body .popover .arrow::before,
  body .popover .arrow::after {
    border-width: 0 .5rem .5rem;
  }

  body .popover.bs-popover-bottom .arrow::before,
  body .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: -7px;
  }

  body .popover.bs-popover-bottom .arrow::after,
  body .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: -6px;
    border-bottom-color: #f7f7f7;
  }
`;

class Root extends Component {
  async componentDidMount() {
    appInsights.trackPageView();
    if (this.props.location.pathname === "/callback") return;
    try {
      await auth.silentAuth();
      this.forceUpdate();
    } catch (err) {
      if (err.error === "login_required") return;
      console.log(err.error);
    }
  }

  render() {
    return (
      <AppInsightsContext.Provider value={reactPlugin}>
        <AppInsightsErrorBoundary
          onError={() => <h1>Something went wrong</h1>}
          appInsights={reactPlugin}
        >
          <Fragment>
            <CustomProperties global properties={customVariables}>
              <Switch>
                <Route exact path="/" component={Login} />
                {/* For all other routes */}
                <GuardedRoute exact path="/welcome" component={App} />
                <GuardedRoute exact path="/teams" component={App} />
                <GuardedRoute path="/teams/review/:id" component={App} />
                <GuardedRoute exact path="/management" component={App} />
                <GuardedRoute
                  exact
                  path="/management/students"
                  component={App}
                />
                <GuardedRoute path="/management/students/:id" component={App} />
                <GuardedRoute
                  exact
                  path="/management/classrooms"
                  component={App}
                />
                <GuardedRoute exact path="/settings" component={App} />
                <GuardedRoute exact path="/" component={App} />
                <GuardedRoute exact path="/planning" component={App} />
                <GuardedRoute exact path="/backlog" component={App} />
                <GuardedRoute exact path="/scrumboard" component={App} />
                <GuardedRoute exact path="/calendar" component={App} />
                <GuardedRoute exact path="/run-sprint" component={App} />
                <GuardedRoute exact path="/reports" component={App} />
                <GuardedRoute exact path="/reports/history" component={App} />
                <GuardedRoute
                  exact
                  path="/reports/memberLoad"
                  component={App}
                />
                <GuardedRoute
                  exact
                  path="/reports/sprintBurndown"
                  component={App}
                />
                <GuardedRoute
                  exact
                  path="/reports/velocityTrend"
                  component={App}
                />
                <GuardedRoute
                  exact
                  path="/reports/cumulativeFlow"
                  component={App}
                />
                <GuardedRoute exact path="/reports/endOfRun" component={App} />
                <GuardedRoute exact path="/team-members" component={App} />
                <Route exact path="/callback" component={Callback} />
                <Route component={Page404} />
              </Switch>
              <GlobalStyle />
            </CustomProperties>
          </Fragment>
        </AppInsightsErrorBoundary>
      </AppInsightsContext.Provider>
    );
  }
}

export default withRouter(Root);
