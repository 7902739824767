import React, { useReducer, createContext } from "react";

export const ProjectContext = createContext();

export const { Consumer } = ProjectContext;

export const ProjectContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ProjectContext.Provider value={[state, dispatch]}>
      {props.children}
    </ProjectContext.Provider>
  );
};

export const initialState = {
  loading: true,
  currentTeamId: 0,
  currentTeamStatus: "",
  currentSprintId: 0,
  currentSprintNumber: 0,
  currentWeekNumber: 0,
  currentRunDay: 0,
  runSequenceNumber: 0,
  sprints: [],
  hasActiveTeam: false,
  autoRun: false,
  autoRunRandom: false,
  currentWeekDay: "MONDAY",
  conditionResponse: 0,
  teamStudentId: 0,
  teamStudentFirst: "",
  teamStudentLast: "",
  sprintOptions: [],
  selectedSprintOption: {},
  instructorSelectedClassIndex: -1,
  highlightChat: false,
  selectedDropdownValueInSprint: {}
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STUDENT": {
      // has no active team
      return {
        ...state,
        loading: false,
        hasActiveTeam: false,
        teamStudentId: action.payload.studentId,
        teamStudentFirst: action.payload.studentFirst,
        teamStudentLast: action.payload.studentLast
      };
    }
    case "SET_INSTRUCTOR_CLASS_INDEX": {
      return {
        ...state,
        instructorSelectedClassIndex: action.payload.index
      };
    }
    case "UPDATE_LOCAL_TEAM": {
      // set up sprint selector options
      const sprintOptions = action.payload.sprints
        ? action.payload.sprints
            .map(item => {
              return {
                ...state,
                loading: false,
                sprintNumber: item.sprintNumber,
                label: `Sprint ${item.sprintNumber.toString()}`,
                value: item.id.toString(),
                status: item.status
              };
            })
            .sort((a, b) => b.sprintNumber - a.sprintNumber)
        : [];

      let defaultSprint = sprintOptions.find(s => s.status === "COMPLETE");
      if (!defaultSprint) {
        defaultSprint = sprintOptions.find(
          s => s.status === "PLANNING" || s.status === "STARTED"
        );
      }
      return {
        ...state,
        loading: false,
        currentTeamId: action.payload.currentTeamId,
        currentTeamStatus: action.payload.currentTeamStatus,
        currentSprintId: action.payload.currentSprintId,
        currentSprintNumber: action.payload.currentSprintNumber,
        currentWeekNumber: action.payload.currentWeekNumber,
        currentWeekDay: action.payload.currentWeekDay,
        currentRunDay: action.payload.currentRunDay,
        runSequenceNumber: action.payload.runSequenceNumber,
        sprints: action.payload.sprints,
        hasActiveTeam: true,
        teamStudentId: action.payload.studentId,
        teamStudentFirst: action.payload.studentFirst,
        teamStudentLast: action.payload.studentLast,
        sprintOptions: [
          ...sprintOptions.sort((a, b) => b.sprintNumber - a.sprintNumber)
        ],
        selectedSprintOption: defaultSprint
      };
    }
    case "RUN_WEEK_UPDATE": {
      return {
        ...state,
        loading: false,
        currentTeamStatus: action.payload.currentTeamStatus,
        currentSprintId: action.payload.currentSprintId,
        currentSprintNumber: action.payload.currentSprintNumber,
        currentWeekNumber: action.payload.currentWeekNumber,
        currentWeekDay: action.payload.currentWeekDay,
        currentRunDay: action.payload.currentRunDay
      };
    }
    case "UPDATE_SELECTED_SPRINT": {
      return {
        ...state,
        selectedSprintOption: action.payload.option,
        selectedDropdownValueInSprint: action.payload.option
      };
    }
    case "UPDATE_SELECTED_SPRINT_IN_DROPDOWN": {
      return {
        ...state,
        selectedDropdownValueInSprint: action.payload.option
      };
    }
    case "HIGHLIGHT_CHAT": {
      return {
        ...state,
        highlightChat: action.payload.option
      };
    }
    case "RESET_AFTER_DELETE": {
      return {
        ...initialState
      };
    }
    default:
      return {
        ...state
      };
  }
};