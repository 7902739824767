//General
import AgileCoach from "./AgileCoach.png";
import Team from "./Team.png";

//Other Players
import BDSmith from "./BDSmith.png";
import Clem from "./Clem.png";
import DougKeeper from "./DougKeeper.png";
import FarleyGnu from "./FarleyGnu.png";
import Farley from "./Farley.png";
import MarkJarchow from "./MarkJarchow.png";
import HankHacker from "./HankHacker.png";
import JamieBurns from "./JamieBurns.png";
import KittyFrost from "./KittyFrost.png";
import MaryProbst from "./MaryProbst.png";
import OMSmith from "./OMSmith.png";
import MikeRussell from "./MikeRussell.png";
import SailBoat from "./SailBoat.png";
import PhillipBowden from "./PhillipBowden.jpg";

//Developers
import BarbaraElo from "./BarbaraElo.png";
import FredSaville from "./FredSaville.png";
import JumaGadach from "./JumaGadach.png";
import KamalAmin from "./KamalAmin.png";
import KatinaMalke from "./KatinaMalke.png";
import MariaTidwell from "./MariaTidwell.png";
import NickZimmerman from "./NickZimmerman.png";
import RusselReit from "./RusselReit.png";
import SladiPetrov from "./SladiPetrov.png";
import YeeLee from "./YeeLee.png";

export default {
  AgileCoach,
  Team,
  BDSmith,
  Clem,
  DougKeeper,
  FarleyGnu,
  Farley,
  MarkJarchow,
  HankHacker,
  JamieBurns,
  KittyFrost,
  MikeRussell,
  PhillipBowden,
  MaryProbst,
  OMSmith,
  BarbaraElo,
  FredSaville,
  JumaGadach,
  KamalAmin,
  KatinaMalke,
  MariaTidwell,
  NickZimmerman,
  RusselReit,
  SladiPetrov,
  YeeLee,
  SailBoat
};

export const roles = {
  OMSmith: "CEO",
  BDSmith: "President & COO",
  FarleyGnu: "Product Owner",
  Farley: "Product Owner",
  MikeRussell: "VP Human Resources (Sponsor)",
  PhillipBowden: "Manager of Payroll (Customer)",
  BarbaraElo: "Developer",
  FredSaville: "Developer",
  JumaGadach: "Developer",
  KamalAmin: "Developer",
  KatinaMalke: "Developer",
  MariaTidwell: "Developer",
  NickZimmerman: "Developer",
  RusselReit: "Developer",
  SladiPetrov: "Developer",
  YeeLee: "Developer"
};
