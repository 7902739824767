import React, { useState, useMemo, useContext } from "react";
import styled from "styled-components";
import { Col, Row, Button, Card, CardHeader, CardBody } from "reactstrap";
import ReactToPrint from "react-to-print";
import { useQuery } from "@apollo/client";
import { ProjectContext } from "GlobalState";
import Loading, { Error } from "../../components/Loading";
import EndRunSummary from "./components/EndRunSummary";
import { TEAM_QUERY, STORIES_QUERY } from "../../graphql/queries";
import UserHelper from "../../UserHelper";
import { useEffect } from "react";

const PrintDiv = styled.div`
  @media print {
    @page {
      size: landscape;
    }
    padding: 50px;
  }
`;

const EndOfRun = ({ currentUser }) => {

  useEffect(() => {
    let x = document.getElementsByClassName("nav-dropdown-items")[0];

    if (x && x.itemId !== null) {
      x.parentElement.classList.toggle("open");
      x.itemId = null;
    }
  }, []);

  const [historyRef, setHistoryRef] = useState(null);
  const [state] = useContext(ProjectContext);
  const today = new Date();
  const date = `${today.getFullYear()}-${today.getMonth() +
    1}-${today.getDate()}`;
  const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  const { loading: tLoading, error: tError, data: teamData } = useQuery(
    TEAM_QUERY,
    {
      variables: { teamId: state.currentTeamId },
      skip: !state.currentTeamId
    }
  );
  const { loading: sLoading, error: sError, data: storiesData } = useQuery(
    STORIES_QUERY,
    {
      variables: { teamId: state.currentTeamId },
      skip: !state.currentTeamId
    }
  );
  const perSprintVelocities = useMemo(() => {
    if (
      !storiesData ||
      !storiesData.stories ||
      !storiesData.stories.length > 0
    ) {
      return [];
    }
    const returnList = [];
    const filteredStories = storiesData.stories.filter(
      s => s.currentSprint !== null && s.status.toUpperCase() === "COMPLETE"
    );
    for (let i = 1; i <= 4; i += 1) {
      const completedSprintStories = filteredStories.filter(
        s => s.currentSprint.sprintNumber === i
      );
      const velocity = completedSprintStories.reduce(
        (points, current) => points + current.storyPoints,
        0
      );

      const obj = {
        sprint: i,
        completedStories: completedSprintStories.length,
        velocity
      };
      returnList.push(obj);
    }
    return [...returnList];
  }, [storiesData]);
  // eslint-disable-next-line no-unused-vars
  let documentTitle = null;

  const afterPrint = () => {
    documentTitle = document.title;
  };

  const beforePrint = newTitle => {
    documentTitle = newTitle;
  };

  if (tLoading || sLoading) {
    return <Loading />;
  }

  if (sError) return <Error error={sError} />;
  if (tError) return <Error error={tError} />;
  // TODO is this just performing an initial cap conversion?
  // this.state.stories.forEach(story => {
  //   story.status =
  //     story.status.charAt(0).toUpperCase() +
  //     story.status.slice(1).toLowerCase();
  // });
  const teamVelocity = perSprintVelocities.reduce(
    (tv, current) => tv + current.velocity,
    0
  );

  // velocityforEach(s => (tv += s.velocity));
  const averageTeamVelocity = Math.round(teamVelocity / 4);
  if (!teamData) {
    return <span>No run available</span>;
  }

  if (sLoading || tLoading) {
    return <Loading />;
  }
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <span className="float-sm-right">
              <ReactToPrint
                trigger={() => (
                  <Button color="secondary">
                    <i className="far fa-file-pdf" /> Print
                  </Button>
                )}
                content={() => historyRef}
                onBeforePrint={() =>
                  beforePrint(`End Of Run ${state.currentSprintNumber}`)
                }
                onAfterPrint={() => afterPrint()}
                documentTitle={`${"End of Run Report" +
                  "_"}${UserHelper.firstName(
                  currentUser,
                  state
                )}${UserHelper.lastName(
                  currentUser,
                  state
                )}_${date}_${time}_${UserHelper.teamId(currentUser, state)}`}
              />
            </span>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <PrintDiv
          id={`h${"state.currentSprintNumber.toString()"}`}
          ref={el => {
            setHistoryRef(el);
          }}
        >
          <EndRunSummary
            teamData={teamData}
            perSprintVelocities={perSprintVelocities}
            averageTeamVelocity={averageTeamVelocity}
            stories={storiesData.stories}
            currentUser={currentUser}
          />
        </PrintDiv>
      </CardBody>
    </Card>
  );
};

export default EndOfRun;
